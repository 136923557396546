<template>
  <div v-if="show" id="inventoryCheck">
    <div class="inventoryCheck_msg">
      <van-image width="3rem" height="2.3rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/BDxcx/inventory/confirm_img.png"
                 style="margin-top:3rem"
      />
      <div class="msg_01">哎呀~这不是您的盘存</div>
      <div class="msg_02">请确认账号登录状态！重新登录试试~</div>
      <br><br>
    </div>
    <div class="inventoryCheck_btn">
      <div class="inventoryCheck_reLogin" @click="loginOut">重新登录</div>
    </div>
  </div>
</template>

<script>
import { frontendTaking_checkTaking, backendTaking_checkTaking } from '@/api/linkPage'
export default {
  data() {
    return {
      show: false,
      btid: '',
      type: '',
      path: ''
    }
  },
  created() {
    this.btid = this.$route.query.btid
    this.type = this.$route.query.type // 1前店 2后仓
    this.path = this.$route.path + '?btid=' + this.$route.query.btid + '&type=' + this.$route.query.type
    if (parseInt(this.type) === 1) {
      this.checkUser1(this.btid, this.type)
    }
    if (parseInt(this.type) === 2) {
      this.checkUser2(this.btid, this.type)
    }
  },
  methods: {
    checkUser1(id, type) {
      frontendTaking_checkTaking({ frontendTakingId: this.btid }).then(res => {
        this.$router.push('/linkPage/comfirmInventory?btid=' + id + '&type=' + type)
      }).catch(() => { this.show = true })
    },
    checkUser2(id, type) {
      backendTaking_checkTaking({ backendTakingId: this.btid }).then(res => {
        this.$router.push('/linkPage/comfirmInventory?btid=' + id + '&type=' + type)
      }).catch(() => { this.show = true })
    },
    loginOut() {
      sessionStorage.removeItem('jump')
      sessionStorage.setItem('shareUrl', this.path)
      var url = '#' + this.path
      window.location.replace(url)
      this.$router.replace({ path: '/user/login' })
    }

  }
}
</script>

<style lang="scss">
#inventoryCheck {
  width: 100%;
  height: 18rem;
  background-image: url(https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/BDxcx/inventory/confirm_bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: rgb(200, 222, 255);

  .inventoryCheck_msg {
    width: 100%;
    height: 6rem;
    background-image: url(https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/BDxcx/inventory/confirm_box.png);
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .msg_01 {
      font-size: 0.5rem;
      font-weight: bold;
      color: #15355c;
      margin-top: 0.2rem;
    }
    .msg_02 {
      font-size: 0.3rem;
      font-weight: 400;
      color: #15355c;
      margin-top: 0.2rem;
    }
  }
  .inventoryCheck_btn {
    width: 6rem;
    height: 1rem;
    background: linear-gradient(8deg, #159cf8 0%, #3c7cff 100%);
    border-radius: 0.5rem;
    margin-left: 2rem;
    margin-top: 5rem;
    text-align: center;
    .inventoryCheck_reLogin {
      font-size: 0.4rem;
      font-weight: 400;
      line-height: 1rem;
      color: #ffffff;
    }
  }
}
</style>
